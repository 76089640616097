import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { UmbracoNotFoundPage } from '@shared/types';
import { useCurrentLang } from 'hooks/useCurrentLang';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import Button from 'components/elements/Button';
import Typography from 'components/elements/Typography/Typography';
import findByLang from 'utils/findByLang';

import { INotFoundQueryResult } from './models';

import './404.scss';

const NotFoundPage = () => {
  const [result, setResult] = useState<UmbracoNotFoundPage.INode | null>();
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allNotFoundPage: { nodes },
  }: INotFoundQueryResult = useStaticQuery(graphql`
    {
      allNotFoundPage {
        nodes {
          lang
          title
          title404 {
            ...FragmentTypography
          }
          text {
            ...FragmentTypography
          }
          ctaButton {
            ...FragmentButton
          }
          showBreadcrumbsDesktop
          showBreadcrumbsMobile
          urls {
            ...FragmentUrlType
          }
          seo {
            ...SEOStructureFragment
          }
        }
      }
    }
  `);

  useEffect(() => {
    const content = nodes.find(({ lang }) => findByLang(lang, currentLangCode));
    setResult(content);
  }, [currentLangCode, nodes]);

  return (
    <Layout
      seo={result?.seo}
      lang={currentLangCode}
      urls={result?.urls}
      pagePathname="404"
      className="notFound-page"
    >
      <Container className="notFound-page__container">
        <div data-testid="NotFoundPage">
          {result?.title404 ? (
            <Typography data={result.title404} customClass="notFound-page__title" />
          ) : null}
          {result?.text ? (
            <Typography data={result.text} customClass="notFound-page__text" />
          ) : null}
          {result?.ctaButton?.length ? (
            <div className="notFound-page__cta">
              <Button data={[result.ctaButton[0]]} />
            </div>
          ) : null}
        </div>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
